import { Field, Form, Formik, useFormik } from "formik";
import Config from './config.json';


const listDebitCards = ["548913", "515452", "521566", "547472", "530130", "522406", "522403", "532767", "679932", "535786", "535789", "535787", "521566", "540326", "555890", "526829", "530915", "517311"];
const listCreditCards = ["417641", "405488", "420852", "401014", "425555", "526184", "525716"];

const validate = (values, props /* only available when using withFormik */) => {
    const errors = {};

    if (!values.card) {
        errors.card = 'Required';
    } else {
        const isValidDebit = listDebitCards.includes(values.card);
        const isValidCredit = listCreditCards.includes(values.card);

        if (!isValidDebit && !isValidCredit) {
            errors.card = 'Introduzca una tarjeta de Openbank';
        }
    }

    //...

    return errors;
};

export const FormCard = () => {

    return (
        <>
            <div class="header"><img src="/logotipos/diver-logo-desktop.png" alt="" /></div>
            {/* <p class="info_bines">Preventa exclusiva clientes Openbank <br /><span class="deco_bines"><img src="/decos/deco-naranja-i.svg" alt="Lamp" width="32" height="32" /></span>del 23 al 29 de agosto 12:00 pm<span class="deco_bines"><img src="/decos/deco-naranja-d.svg" alt="Lamp" width="32" height="32" /></span></p> */}
            <h4>Introduzca los 6 primeros dígitos de su tarjeta Openbank</h4>
            <Formik
                initialValues={{
                    card: ''
                }}
                validate={validate}
                onSubmit={values => {
                    // same shape as initial values
                    console.log(values);
                    const nextUrl = "";
                    if (listDebitCards.includes(values.card)) {
                        window.location.assign(Config.urls.client_debit);

                    }
                    else if (listCreditCards.includes(values.card)) {
                        //window.location.assign("https://credentradasopenbank.rockcircus.show/espectaculo/rock-circus/ROCKCIRCUS");
                        window.location.assign(Config.urls.client_credit);
                    }

                }}
            >
                {({ errors, touched }) => (
                    <Form>
                        <Field name="card" />
                        {/* If this field has been touched, and it contains an error, display it
            */}
                        {touched.card && errors.card && <div style={{ fontWeight: 'bold' }}>{errors.card}</div>}
                        <button type="submit">Enviar</button>
                    </Form>
                )}
            </Formik>
        </>

    );
};